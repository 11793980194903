import { BasePortalDetailedActivityViewStore } from "administration/analytics/stores";

class NakiNigunAlbumActivityViewStore extends BasePortalDetailedActivityViewStore {
    podcastId = null;

    constructor(rootStore, { routeStore }) {
        super(rootStore, {
            chartLabel: "Playbacks",
        });
        this.routeStore = routeStore;

        this.nakiNigunAlbumId = rootStore.routerStore.routerState.params.id;
    }

    getActivityChartData(filter, abortController) {
        return this.routeStore.getNakiNigunAlbumActivity(this.nakiNigunAlbumId, filter, abortController);
    }

    fetchSummary(params, abortController) {
        params.embed = "nakiNigunAlbum";
        return this.routeStore.getNakiNigunAlbumSummary(this.nakiNigunAlbumId, params, abortController);
    }
}

export default NakiNigunAlbumActivityViewStore;
