import React from "react";
import { PropTypes } from "prop-types";
import { mergeOrOverrideCss } from "../../common/utils";
import { observer } from "mobx-react";
import { map } from "lodash";

function MrbRadioButtons({
    id,
    value,
    onChange,
    options,
    t = (i) => i,
    disabled,
    rowView = true,
    labelRender = null,
    classNameExtend,
    classNameOverride,
    inputClassNameOverride,
    inputClassNameExtend,
    labelClassNameOverride,
}) {
    const WrapElement = rowView ? React.Fragment : ({ children }) => <div>{children}</div>;
    return (
        <div className={mergeOrOverrideCss("u-display--flex", classNameOverride, classNameExtend)}>
            {map(options, (option, index) => {
                const itemId = `${id}_${option.value}`;
                const isChecked = value === option.value;
                const defaultClass = `c-segmented ${
                    index === 0 ? " c-segmented--first" : `${index === options.length - 1 ? " c-segmented--last" : ""}`
                }`;
                return (
                    <WrapElement key={itemId}>
                        <input
                            id={itemId}
                            value={option.value}
                            checked={isChecked}
                            onChange={() => onChange(option.value)}
                            disabled={disabled}
                            type="radio"
                            tabIndex={0}
                            className={`${mergeOrOverrideCss(
                                defaultClass,
                                inputClassNameOverride,
                                inputClassNameExtend
                            )} ${isChecked ? "active" : ""}`}
                        />
                        {labelRender ? (
                            labelRender({ itemId, t })
                        ) : (
                            <label className={labelClassNameOverride} htmlFor={itemId}>
                                {t(option.label)}
                            </label>
                        )}
                    </WrapElement>
                );
            })}
        </div>
    );
}

MrbRadioButtons.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    t: PropTypes.func,
    labelRender: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
};

MrbRadioButtons.defaultProps = {
    disabled: false,
};

export default observer(MrbRadioButtons);
