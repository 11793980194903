import { MrbBaseRouteStore } from "mrb/core";
import {
    DeviceAnalyticsService,
    StationAnalyticsService,
    PodcastAnalyticsService,
    PortalAnalyticsService,
    NakiNigunAnalyticsService,
} from "common/services";

class AnalyticsDashboardRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.deviceAnalyticsService = this.rootStore.createApplicationService(DeviceAnalyticsService);
        this.stationAnalyticsService = this.rootStore.createApplicationService(StationAnalyticsService);
        this.podcastAnalyticsService = this.rootStore.createApplicationService(PodcastAnalyticsService);
        this.nakiNigunAnalyticsService = this.rootStore.createApplicationService(NakiNigunAnalyticsService);
        this.portalAnalyticsService = this.rootStore.createApplicationService(PortalAnalyticsService);
    }

    async getDeviceActivity(filter, abortController) {
        const response = await this.deviceAnalyticsService.getDeviceActivity(filter, abortController);
        return response.data;
    }

    async getTrendingStations(filter, abortController) {
        const response = await this.stationAnalyticsService.getTrendingStations(filter, abortController);
        return response.data;
    }

    async getTrendingPodcasts(filter, abortController) {
        const response = await this.podcastAnalyticsService.getTrendingPodcasts(filter, abortController);
        return response.data;
    }

    async getTrendingNakiNigunAlbums(filter, abortController) {
        const response = await this.nakiNigunAnalyticsService.getTrendingNakiNigunAlbums(filter, abortController);
        return response.data;
    }

    async getPortalAnalytics(filter, abortController) {
        const response = await this.portalAnalyticsService.getPortalAnalytics(filter, abortController);
        return response.data;
    }
}

export default AnalyticsDashboardRouteStore;
