import { MrbBaseRouteStore } from "mrb/core";
import { NakiNigunAnalyticsService, NakiNigunAlbumFileStreamService } from "common/services";
import { createBaasicAuthenticatedMediaVaultUrl } from "mrb/baasic";

class TrendingNakiNigunAlbumListRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = rootStore.createApplicationService(NakiNigunAnalyticsService);
        this.NakiNigunAlbumFileStreamService = this.rootStore.createApplicationService(NakiNigunAlbumFileStreamService);
    }

    async getTrendingNakiNigunAlbums(filter) {
        const response = await this.service.getTrendingNakiNigunAlbums(filter);
        return response.data;
    }

    createNakiNigunAlbumCoverImageUrl(coverImageId, width = null, height = null) {
        return createBaasicAuthenticatedMediaVaultUrl(
            this.rootStore,
            this.NakiNigunAlbumFileStreamService.routeService.get(coverImageId),
            {
                width: width,
                height: height,
            }
        );
    }
}

export default TrendingNakiNigunAlbumListRouteStore;
