import React from "react";
import { PropTypes } from "prop-types";
import { observer } from "mobx-react";

function MrbQuerySearchInput({
    queryUtility,
    propertyName = "search",
    placeholder = "Search",
    className = "c-input c-input--search c-input--base",
    t = (i) => i,
    searchButtonLabel = "Search",
    showSearchButton = true,
    autoFocus = false,
    maxLength = null,
}) {
    const onChange = (event) => {
        queryUtility.filter.set(propertyName, event.target.value);
    };

    const onKeyPress = (event) => {
        if (event.key === "Enter") {
            queryUtility.fetch();
        }
    };

    const onInlineReset = () => {
        queryUtility.filter.set(propertyName, null);

        queryUtility.fetch();
    };

    return (
        <React.Fragment>
            <div className="u-display--flex">
                <div className="u-position--rel u-mar--right--tny">
                    <input
                        className={`${className} ${
                            queryUtility.filter[propertyName] && queryUtility.filter[propertyName] !== "" ? "clear" : ""
                        }`}
                        type="text"
                        placeholder={t(placeholder)}
                        value={queryUtility.filter[propertyName] || ""}
                        onChange={onChange}
                        onKeyPress={onKeyPress}
                        autoFocus={autoFocus}
                        maxLength={maxLength}
                    />
                    {queryUtility.filter[propertyName] && queryUtility.filter[propertyName] !== "" && (
                        <span className="c-btn--clear" onClick={onInlineReset}>
                            <i className="u-icon u-icon--base u-icon--close--primary"></i>
                        </span>
                    )}
                </div>
                {showSearchButton && (
                    <div>
                        <button
                            aria-label={t(searchButtonLabel)}
                            className="c-btn c-btn--base c-btn--primary"
                            onClick={queryUtility.fetch}
                        >
                            <span className="align--v--bottom">{t(searchButtonLabel)}</span>
                        </button>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}

MrbQuerySearchInput.propTypes = {
    queryUtility: PropTypes.object.isRequired,
    propertyName: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    t: PropTypes.func,
    searchButtonLabel: PropTypes.string,
    showSearchButton: PropTypes.bool,
    autoFocus: PropTypes.bool,
    maxLength: PropTypes.number,
};

export default observer(MrbQuerySearchInput);
