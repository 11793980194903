import React from "react";
import { PropTypes } from "prop-types";
import { map } from "lodash";
import { observer } from "mobx-react";
import { HomeGridCell } from "application/home/components";

function HomeCategoryTemplate({ item, goTo, premiumSubscriptionStore, homeManageSelectedItemsViewStore, onCellClick }) {
    return (
        <div className="u-mar--bottom--sml">
            <p className="u-type--base u-type--wgt--bold u-mar--bottom--tny">
                {item.genreName !== null ? item.genreName : "General Content"}
            </p>
            <div className="u-display--flex u-gap--sml u-display--flex--wrap">
                {map(item.homeContents, (item) => (
                    <div>
                        <HomeGridCell
                            goTo={goTo}
                            premiumSubscriptionStore={premiumSubscriptionStore}
                            homeManageSelectedItemsViewStore={homeManageSelectedItemsViewStore}
                            item={item}
                            onCellClick={onCellClick}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}

HomeCategoryTemplate.propTypes = {
    onCellClick: PropTypes.func.isRequired,
    premiumSubscriptionStore: PropTypes.object.isRequired,
    goTo: PropTypes.func.isRequired,
    homeManageSelectedItemsViewStore: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
};

export default observer(HomeCategoryTemplate);
