import { action, observable, makeObservable, runInAction, computed } from "mobx";
import { MrbBaseViewStore } from "mrb/core";
import { httpStatusCodes } from "mrb/core/constants";

class TopTrendingPodcastsViewStore extends MrbBaseViewStore {
    @observable.ref data = [];
    @observable selectedFilterOption = 1;

    filter = null;

    filterOptions = [
        { value: 1, label: "Streams" },
        { value: 2, label: "Unique Listeners" },
    ];

    @computed get uniqueListeners() {
        return this.selectedFilterOption === 2;
    }

    constructor(rootStore, { routeStore }) {
        super(rootStore);
        makeObservable(this);
        this.routeStore = routeStore;
    }

    onInit() {
        return Promise.resolve();
    }

    @action.bound
    setFilterOption(value) {
        this.selectedFilterOption = value;
        this.getResources(this.filter);
    }

    async getResources(filter, abortController) {
        this.filter = filter;
        try {
            this.loaderStore.suspend();
            const params = this.createParams(filter);
            const data = await this.routeStore.getTrendingPodcasts(params, abortController);
            runInAction(() => {
                this.setData(data.item);
                this.loaderStore.resume();
            });
        } catch (error) {
            if (error && error.statusCode === httpStatusCodes.CanceledRequest) {
                return;
            }
            this.rootStore.notificationStore.error(
                "An unexpected error occurred while trying to load podcasts.",
                error
            );
            this.loaderStore.resume();
        }
    }

    createParams(filter) {
        const obj = {
            from: filter.from,
            to: filter.to,
            previousCycleFrom: filter.previousCycleFrom,
            previousCycleTo: filter.previousCycleTo,
            timeZone: filter.timeZone,
            pageNumber: 1,
            pageSize: 10,
            uniqueListeners: this.uniqueListeners,
        };

        return obj;
    }

    @action.bound
    setData(data) {
        this.data = data;
    }
}

export default TopTrendingPodcastsViewStore;
