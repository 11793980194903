import { MrbBaseInfiniteScrollListViewStore } from "mrb/core";
import { AnalyticsTimeRangeViewStore } from "administration/analytics/stores";
import { AnalyticsFilter } from "administration/analytics/filters";
import { timeRangeType } from "administration/analytics/constants";
import { MrbInfiniteScrollGridViewStore } from "mrb/components/infinite-scroll-grid";
import { action, observable, computed, makeObservable } from "mobx";

class TrendingStationListViewStore extends MrbBaseInfiniteScrollListViewStore {
    @observable selectedFilterOption = 1;

    filterOptions = [
        { value: 1, label: "Streams" },
        { value: 2, label: "Unique Listeners" },
    ];

    @computed get uniqueListeners() {
        return this.selectedFilterOption === 2;
    }
    constructor(rootStore, { routeStore }) {
        super(rootStore, {
            queryConfig: {
                filter: new AnalyticsFilter(),
                paramKeyMap: {
                    _range: "range",
                },
                ignoreQueryParams: ["page", "timeZone", "rpp"],
                queryParamMap: {
                    from: {
                        toQuery: (value) => {
                            if (this.queryUtility.filter.range === timeRangeType.custom) {
                                return value;
                            }
                            return undefined;
                        },
                    },
                    to: {
                        toQuery: (value) => {
                            if (this.queryUtility.filter.range === timeRangeType.custom) {
                                return value;
                            }
                            return undefined;
                        },
                    },
                },
            },
        });
        makeObservable(this);
        this.routeStore = routeStore;

        this.analyticsTimeRangeViewStore = new AnalyticsTimeRangeViewStore(this.rootStore, {
            queryUtility: this.queryUtility,
        });

        this.setGridStore(
            new MrbInfiniteScrollGridViewStore(this.rootStore, this.queryUtility, {
                generateKey: (item) => item.id,
                mapper: (item) => {
                    return {
                        id: item.stationId,
                        title: item.station ? item.station.title : "Unknown station",
                        isPremium: item.station && item.station.isPremium,
                        coverImageUrl: this.createCoverImageUrl(item.station),
                        score: item.currentCycleScore,
                        currentCyclePosition: item.currentCyclePosition,
                        previousCyclePosition: item.previousCyclePosition,
                        routeParams: item.station
                            ? {
                                  route: "master.administration.analytics.station-activity",
                                  params: { id: item.station.id },
                                  queryParams: this.rootStore.routerStore.routerState.queryParams,
                              }
                            : null,
                    };
                },
            })
        );

        this.createCoverImageUrl = this.createCoverImageUrl.bind(this);
    }

    find(params) {
        const filter = {
            from: params.from,
            to: params.to,
            previousCycleFrom: params.previousCycleFrom,
            previousCycleTo: params.previousCycleTo,
            timeZone: params.timeZone,
            pageNumber: params.pageNumber,
            pageSize: 30,
            uniqueListeners: this.uniqueListeners,
        };
        return this.routeStore.getTrendingStations(filter);
    }

    createCoverImageUrl(item) {
        if (item && item.coverImageId) {
            return this.routeStore.createStationCoverImageUrl(item.coverImageId, 150, 150);
        }
        return null;
    }

    @action.bound
    setFilterOption(value) {
        this.selectedFilterOption = value;
        this.analyticsTimeRangeViewStore.queryUtility.fetch();
    }
}

export default TrendingStationListViewStore;
