import { BaseApiRouteService } from "mrb/core";

class PodcastAnalyticsApiRouteService extends BaseApiRouteService {
    constructor() {
        super("analytics/podcasts");
    }

    getTrendingPodcasts(filter) {
        return super.find(
            `${this.base}/{?from,to,previousCycleFrom,previousCycleTo,timeZone,page,rpp,uniqueListeners}`,
            filter
        );
    }

    getPodcastActivity(id, options) {
        return super.get(`${this.base}/{id}{?from,to,previousCycleFrom,previousCycleTo,timeZone}`, id, options);
    }

    getPodcastStats(id, options) {
        return super.get(`${this.base}/{id}/stats{?from,to,previousCycleFrom,previousCycleTo,timeZone}`, id, options);
    }

    getPodcastSummary(id, options) {
        return super.get(
            `${this.base}/{id}/summary{?from,to,previousCycleFrom,previousCycleTo,embed,fields}`,
            id,
            options
        );
    }

    getPodcastListenersStats() {
        return super.get(`${this.base}/listeners`);
    }

    getTrendingPodcastsForProducer(filter) {
        return super.find(
            `${this.base}/producer/{?from,to,previousCycleFrom,previousCycleTo,timeZone,page,rpp,uniqueListeners}`,
            filter
        );
    }

    getTrendingPodcastsTotalStreamsForProducer(filter) {
        return super.find(`${this.base}/producer-total/{?from,to,timeZone,uniqueListeners}`, filter);
    }
}

export default PodcastAnalyticsApiRouteService;
