import React from "react";
import { PropTypes } from "prop-types";
import {
    HomeEmptyState,
    HomeOrderBySelect,
    HomeFeaturedContent,
    HomeListLoader,
    HomeManageSelectedItems,
    HomeCategory,
} from "application/home/components";
import { MrbRadioButtons } from "mrb/components/radio";
import { MrbInfiniteScrollGrid } from "mrb/components/infinite-scroll-grid";
import { observer } from "mobx-react";

function HomeMainViewTemplate({ homeViewStore, t }) {
    const {
        gridStore,
        orderBySelectStore,
        goTo,
        rootStore: { premiumSubscriptionStore },
        homeFeaturedContentViewStore,
        homeManageSelectedItemsViewStore,
    } = homeViewStore;
    const { isBulk } = homeManageSelectedItemsViewStore;

    return (
        <React.Fragment>
            <HomeManageSelectedItems homeManageSelectedItemsViewStore={homeManageSelectedItemsViewStore} t={t} />
            <HomeFeaturedContent
                homeFeaturedContentViewStore={homeFeaturedContentViewStore}
                homeManageSelectedItemsViewStore={homeManageSelectedItemsViewStore}
                t={t}
            />
            <div className="c-home__filter">
                <div className="c-home__filter__actions">
                    <div className="u-mar--top--sml u-mar--bottom--sml">
                        <ContentFilter homeViewStore={homeViewStore} t={t} />
                    </div>

                    <div className="c-home__filter__select">
                        <HomeOrderBySelect store={orderBySelectStore} t={t} />
                    </div>
                </div>
            </div>

            <MrbInfiniteScrollGrid
                store={gridStore}
                t={t}
                classNameOverride="c-home__list--secondary"
                loadMoreLoader={<HomeListLoader row={1} />}
                loaderRenderer={() => <HomeListLoader row={3} bulk={isBulk} />}
                cellRenderer={(props) => (
                    <HomeCategory
                        goTo={goTo}
                        premiumSubscriptionStore={premiumSubscriptionStore}
                        homeManageSelectedItemsViewStore={homeManageSelectedItemsViewStore}
                        {...props}
                    />
                )}
                emptyStateRenderer={() => <HomeEmptyState classNameExtend="c-empty-state--no-results--lrg" t={t} />}
            />
        </React.Fragment>
    );
}

HomeMainViewTemplate.propTypes = {
    homeViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

const ContentFilter = observer(function ContentFilter({ homeViewStore, t }) {
    const { premiumDisabledOptions, selectedPremiumDisabledOption, changePremiumDisabledFilter } = homeViewStore;
    return (
        <MrbRadioButtons
            inputClassNameExtend="c-segmented--secondary"
            id="home-prem-dis-control"
            name="home-prem-dis-control"
            options={premiumDisabledOptions}
            value={selectedPremiumDisabledOption}
            onChange={changePremiumDisabledFilter}
            t={t}
        />
    );
});

export default observer(HomeMainViewTemplate);
