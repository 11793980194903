import React from "react";
import { PropTypes } from "prop-types";
import { observer } from "mobx-react";
import { map } from "lodash";
import { mergeCss } from "mrb/common/utils";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { numberFormatter } from "common/utils";
import { TopTrendingEmptyState, TopTrendingListLoader, TrendingIndicator } from "administration/analytics/components";
import { MrbContentLoader } from "mrb/components/loader";
import { MrbRadioButtons } from "baasic-react-bootstrap/src";

function TopTrendingPodcastsTemplate({ topTrendingPodcastsViewStore, t }) {
    const {
        data,
        loaderStore,
        filterOptions,
        selectedFilterOption,
        setFilterOption,
        rootStore: { routerStore },
    } = topTrendingPodcastsViewStore;

    return (
        <div className="u-mar--bottom--med">
            <MrbRouterLink
                routeName="master.administration.analytics.podcast"
                className="c-analytics__title"
                queryParams={routerStore.routerState.queryParams}
            >
                Top 10 trending podcasts
                <i className="u-icon u-icon--base u-icon--arrow-right u-mar--left--tny"></i>
            </MrbRouterLink>
            <div className="u-display--flex u-display--flex--ai--center u-display--flex--jc--space-between u-mar--bottom--xsml">
                <MrbRadioButtons
                    id="trending-podcasts-filter"
                    name="trending-podcasts-filter"
                    inputClassNameExtend="c-segmented--vertical"
                    options={filterOptions}
                    onChange={setFilterOption}
                    value={selectedFilterOption}
                    labelClassNameOverride="u-type--sml"
                />
            </div>
            <div className="c-card--primary c-card--med">
                <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <TopTrendingListLoader noButton />}>
                    {() => (
                        <div className="u-animation--fadeIn">
                            {data && data.length > 0 ? (
                                <div>
                                    {map(data, (item) => (
                                        <div className="c-analytics__list__item" key={item.podcastId}>
                                            <div className="u-display--flex u-display--flex--ai--center">
                                                <TrendingIndicator
                                                    showIncreaseNumber={false}
                                                    currentCycleNumber={item.currentCyclePosition}
                                                    previousCycleNumber={item.previousCyclePosition}
                                                />
                                                <span className="u-mar--right--tny u-mar--left--tny">
                                                    {item.currentCyclePosition}.
                                                </span>
                                                {item.podcast ? (
                                                    <MrbRouterLink
                                                        className={"c-analytics__list__title"}
                                                        routeName="master.administration.analytics.podcast-activity"
                                                        params={{ id: item.podcast.id }}
                                                        queryParams={routerStore.routerState.queryParams}
                                                        title={item.podcast.title}
                                                    >
                                                        {item.podcast.title}
                                                    </MrbRouterLink>
                                                ) : (
                                                    <span>Unknown podcast</span>
                                                )}
                                                <i
                                                    className={mergeCss(
                                                        "u-icon u-icon--base",
                                                        item.podcast && item.podcast.isPremium
                                                            ? "u-icon--premium u-mar--left--tny"
                                                            : null
                                                    )}
                                                ></i>
                                            </div>
                                            <p className="c-analytics__list__count">
                                                {numberFormatter.format(item.currentCycleScore)}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <TopTrendingEmptyState />
                            )}
                        </div>
                    )}
                </MrbContentLoader>
            </div>
        </div>
    );
}

TopTrendingPodcastsTemplate.propTypes = {
    topTrendingPodcastsViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default observer(TopTrendingPodcastsTemplate);
