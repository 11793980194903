import { action, computed, makeObservable, runInAction } from "mobx";
import { MrbBaseListViewStore } from "mrb/core";
import { MrbInfiniteScrollGridViewStore } from "mrb/components/infinite-scroll-grid";
import { MrbSimpleSelectStore, MrbQuerySimpleSelectStore } from "mrb/components/select";
import { MrbModalParams } from "mrb/common/models";
import { HomeContentFilter } from "application/home/filters";
import {
    HomeGenericSearchViewStore,
    HomeFeaturedContentViewStore,
    HomeManageSelectedItemsViewStore,
} from "application/home/stores";
import { UpdatePodcastStatusOptions, UpdateStationStatusOptions } from "application/common/models";
import { HomeGridItemDecorator } from "application/home/models";
import { podcastType, podcastContentType } from "common/constants";
import { find, isNil, intersectionWith, filter, forEach, map } from "lodash";

class HomeViewStore extends MrbBaseListViewStore {
    @computed get selectedDevice() {
        return this.rootStore.userDeviceStore.selectedDevice;
    }

    @computed get inSearchMode() {
        return this.homeGenericSearchViewStore.showSearchView;
    }

    @computed get anyDevice() {
        return this.rootStore.userDeviceStore.devices.length > 0;
    }

    @computed get selectedPremiumDisabledOption() {
        if (!isNil(this.queryUtility.filter.isEnabled)) {
            return this.queryUtility.filter.isEnabled ? 2 : 3;
        } else if (!isNil(this.queryUtility.filter.isPremium)) {
            return 1;
        }
        return 0;
    }

    contentTypeOptions = [
        {
            label: "APPLICATION.HOME.LIST.FILTER.ALL",
            value: 0,
        },
        {
            label: "APPLICATION.HOME.LIST.FILTER.STATIONS",
            value: 1,
        },
        {
            label: "APPLICATION.HOME.LIST.FILTER.PODCASTS",
            value: 2,
        },
    ];

    premiumDisabledOptions = [
        {
            label: "APPLICATION.HOME.LIST.FILTER.ALL",
            value: 0,
        },
        {
            label: "APPLICATION.HOME.LIST.FILTER.PREMIUM",
            value: 1,
        },
        {
            label: "APPLICATION.HOME.LIST.FILTER.ENABLED",
            value: 2,
        },
        {
            label: "APPLICATION.HOME.LIST.FILTER.DISABLED",
            value: 3,
        },
    ];

    orderOptions = [
        {
            label: "APPLICATION.HOME.LIST.ORDER.ALPHABETICALY_OPTION",
            value: "title",
        },
        {
            label: "APPLICATION.HOME.LIST.ORDER.RECENTLY_ADDED_OPTION",
            value: "dateCreated",
        },
    ];

    constructor(rootStore, { routeStore }) {
        const pageSize = 5;
        super(rootStore, {
            queryConfig: {
                filter: new HomeContentFilter(),
                orderBy: "title",
                orderDirection: "asc",
                pageSize: pageSize,
                queryParamMap: {
                    page: {
                        toQuery: () => 1,
                    },
                },
            },
        });

        makeObservable(this);
        this.routeStore = routeStore;
        this.homeGenericSearchViewStore = new HomeGenericSearchViewStore(this.rootStore, this);
        this.homeFeaturedContentViewStore = new HomeFeaturedContentViewStore(this.rootStore, this);
        this.homeManageSelectedItemsViewStore = new HomeManageSelectedItemsViewStore(this.rootStore, this);
        this.connectDeviceModal = new MrbModalParams(
            {
                onClose: () => this.rootStore.userStore.disableConnectDeviceMessage(),
            },
            {
                goToConnectDevice: this.goToConnectDevice,
            }
        );

        this.setGridStore(
            new MrbInfiniteScrollGridViewStore(this.rootStore, this.queryUtility, {
                onCellClick: (item) => this.onClickCellItem(item),
                generateKey: (item) => item.stationId || item.podcastId,
                mapper: (item) => {
                    item.homeContents = map(
                        item.homeContents,
                        (content) =>
                            new HomeGridItemDecorator({
                                ...content,
                                coverImageUrl: this.createCoverImageUrl(content),
                            })
                    );

                    return item;
                },
            })
        );

        this.deviceSelectStore = new MrbSimpleSelectStore(
            {
                textKey: "deviceName",
                valueKey: "id",
                actions: {
                    onChange: (option) => {
                        this.rootStore.userDeviceStore.setSelectedDevice(option.item);
                    },
                },
            },
            this.rootStore.userDeviceStore.devices
        );
        this.deviceSelectStore.setSelectedItem(this.selectedDevice);

        this.orderBySelectStore = new MrbQuerySimpleSelectStore(
            {
                textKey: "label",
                valueKey: "value",
                actions: {
                    onChange: (selectedOrder) => {
                        if (selectedOrder.value === "title") {
                            this.queryUtility.filter.orderDirection = "asc";
                        } else {
                            this.queryUtility.filter.orderDirection = "desc";
                        }
                    },
                },
            },
            this.queryUtility,
            "orderBy",
            this.orderOptions
        );

        this.reaction(
            () => this.selectedDevice,
            () => {
                if (!this.inSearchMode) {
                    this.queryUtility.fetch();
                }
            }
        );

        this.reaction(
            () => this.inSearchMode,
            (inSearchMode) => {
                if (!inSearchMode) {
                    this.queryUtility.fetch();
                }
            }
        );

        this.changeDeviceStationStatus = this.changeDeviceStationStatus.bind(this);
        this.changeDevicePodcastStatus = this.changeDevicePodcastStatus.bind(this);
    }

    onClickCellItem = async (item) => {
        if (item.podcastId && item.podcastType === podcastType.podcastGroup) {
            this.rootStore.routerStore.goTo("master.application.home.podcast-group", {
                id: item.podcastId,
            });
        } else if (item.podcastId && item.podcastContentType === podcastContentType.torahAnytime) {
            this.rootStore.routerStore.goTo("master.application.home.torah-anytime", { id: item.podcastId });
        } else {
            if (item.stationId) {
                const contentAccessResult = this.rootStore.contentAccessValidator.canAccessStation({
                    isPremium: item.isPremium,
                    isLegacySubscriptionValid: item.validLegacySubscription,
                    unavailableInMobileApp: item.isVisibleInApp === false,
                    unavailableInNakiTouch: item.isVisibleInEmbeddedApp === false,
                });
                if (contentAccessResult.canAccess) {
                    await this.changeDeviceStationStatus(item);
                }
            } else if (item.podcastId) {
                const contentAccessResult = this.rootStore.contentAccessValidator.canAccessPodcast({
                    isPremium: item.isPremium,
                    isLegacySubscriptionValid: item.validLegacySubscription,
                    unavailableInMobileApp: item.isVisibleInApp === false,
                    unavailableInNakiTouch: item.isVisibleInEmbeddedApp === false,
                });
                if (contentAccessResult.canAccess) {
                    await this.changeDevicePodcastStatus(item);
                }
            }
        }
    };

    async changeDeviceStationStatus(item) {
        const duplicateStations = [];
        forEach(this.gridStore.data, (x) => {
            if (x.homeContents) {
                duplicateStations.push(...filter(x.homeContents, (content) => content.stationId === item.stationId));
            }
        });
        if (!item.isUpdating) {
            try {
                forEach(duplicateStations, (station) => {
                    station.isUpdating = true;
                });
                this.rootStore.mainLoaderStore.suspend();
                const newValue = !item.isTurnedOn;
                const options = new UpdateStationStatusOptions(
                    item.stationId,
                    item.title,
                    item.isPremium,
                    item.isVisibleInApp === false
                );
                await this.rootStore.userContentManagerStore.updateStationStatus(options, newValue);
                runInAction(() => {
                    forEach(duplicateStations, (station) => {
                        station.isTurnedOn = newValue;
                    });
                    this.updateFeaturedListStationStatus(item.stationId, newValue);
                });
            } catch (err) {
                this.rootStore.notificationStore.error(
                    "Unexpected error occurred. Please contact the support team.",
                    err
                );
            } finally {
                forEach(duplicateStations, (station) => {
                    delete station.isUpdating;
                });
                this.rootStore.mainLoaderStore.resume();
            }
        }
    }

    async changeDevicePodcastStatus(item) {
        const duplicatePodcasts = [];
        forEach(this.gridStore.data, (x) => {
            if (x.homeContents) {
                duplicatePodcasts.push(...filter(x.homeContents, (content) => content.podcastId === item.podcastId));
            }
        });

        if (!item.isUpdating) {
            try {
                forEach(duplicatePodcasts, (podcast) => {
                    podcast.isUpdating = true;
                });

                this.rootStore.mainLoaderStore.suspend();

                const newValue = !item.isTurnedOn;
                const options = new UpdatePodcastStatusOptions(
                    item.podcastId,
                    item.title,
                    item.isPremium,
                    item.isVisibleInApp === false
                );

                await this.rootStore.userContentManagerStore.updatePodcastStatus(options, newValue);

                runInAction(() => {
                    forEach(duplicatePodcasts, (podcast) => {
                        podcast.isTurnedOn = newValue;
                    });
                    this.updateFeaturedListPodcastStatus(item.podcastId, newValue);
                });
            } catch (err) {
                this.rootStore.notificationStore.error(
                    "Unexpected error occurred. Please contact the support team.",
                    err
                );
            } finally {
                forEach(duplicatePodcasts, (podcast) => {
                    delete podcast.isUpdating;
                });

                this.rootStore.mainLoaderStore.resume();
            }
        }
    }

    @action.bound
    changeDeviceSelectedItemsStatus(selectedItems, value) {
        forEach(this.gridStore.data, (data) => {
            if (data.homeContents) {
                let items = intersectionWith(data.homeContents, selectedItems, (item, selectedItem) => {
                    return item.stationId === selectedItem.stationId && item.podcastId === selectedItem.podcastId;
                });
                items.forEach((item) => {
                    item.isTurnedOn = value;
                });
                let featuredItems = intersectionWith(
                    this.homeFeaturedContentViewStore.items,
                    selectedItems,
                    (item, selectedItem) => {
                        return item.stationId === selectedItem.stationId && item.podcastId === selectedItem.podcastId;
                    }
                );
                featuredItems.forEach((item) => {
                    item.isTurnedOn = value;
                });
            }
        });
    }

    goToConnectDevice = () => {
        this.rootStore.routerStore.goTo("master.application.device.create");
    };

    goTo = (item) => {
        if (item.stationId) {
            this.rootStore.routerStore.goTo("master.application.home.station", {
                id: item.stationId,
            });
        } else if (item.podcastId) {
            if (item.podcastType === podcastType.podcast) {
                if (item.podcastContentType === podcastContentType.torahAnytime) {
                    this.rootStore.routerStore.goTo("master.application.home.torah-anytime", { id: item.podcastId });
                } else {
                    this.rootStore.routerStore.goTo("master.application.home.podcast", {
                        id: item.podcastId,
                    });
                }
            } else {
                this.rootStore.routerStore.goTo("master.application.home.podcast-group", {
                    id: item.podcastId,
                });
            }
        }
    };

    onInit() {
        if (!this.anyDevice && this.rootStore.userStore.isConnectDeviceMessageVisible) {
            this.connectDeviceModal.open();
        }
        return Promise.all([this.homeFeaturedContentViewStore.onInit(), this.queryUtility.initialize()]);
    }

    find(params) {
        if (this.selectedDevice) {
            params.deviceId = this.selectedDevice.id;
        }
        return this.routeStore.findUserContent(params);
    }

    async fetchResources(filter) {
        // Display grid (Skeleton loader) only during the first page load, and in other cases, during loading more data on scroll event,
        // display only top bar loader (mainLoaderStore)
        const isFirstPageLoad = filter.pageNumber === 1;
        if (isFirstPageLoad) {
            this.gridStore.suspend();
        } else {
            this.rootStore.mainLoaderStore.suspend();
        }
        try {
            const response = await this.find(filter);
            runInAction(() => {
                this.gridStore.setData(response);
                if (isFirstPageLoad) {
                    this.gridStore.resume();
                } else {
                    this.rootStore.mainLoaderStore.resume();
                }
            });
        } catch (err) {
            if (isFirstPageLoad) {
                this.gridStore.resume();
            } else {
                this.rootStore.mainLoaderStore.resume();
            }
            this.onFetchError(err);
        }
    }

    onFetchError(error) {
        this.rootStore.notificationStore.error(
            "Unexpected error occurred while trying to load content. Please contact the support team.",
            error
        );
    }

    createCoverImageUrl(item) {
        if (item.coverImageId) {
            if (item.stationId) {
                return this.routeStore.createStationCoverImageUrl(item.coverImageId, 200, 200);
            } else if (item.podcastId) {
                return this.routeStore.createPodcastCoverImageUrl(item.coverImageId, 200, 200);
            }
        }
        return null;
    }

    @action.bound
    changeContentTypeFilter(value) {
        this.queryUtility.filter.set("contentType", value);
        if (this.inSearchMode) {
            this.homeGenericSearchViewStore.queryUtility.fetch();
        } else {
            this.queryUtility.fetch();
        }
    }

    @action.bound
    changePremiumDisabledFilter(value) {
        if (value === 0) {
            this.queryUtility.filter.set("isEnabled", null);
            this.queryUtility.filter.set("isPremium", null);
        } else if (value === 1) {
            this.queryUtility.filter.set("isEnabled", null);
            this.queryUtility.filter.set("isPremium", true);
        } else if (value === 2) {
            this.queryUtility.filter.set("isEnabled", true);
            this.queryUtility.filter.set("isPremium", null);
        } else if (value === 3) {
            this.queryUtility.filter.set("isEnabled", false);
            this.queryUtility.filter.set("isPremium", null);
        }
        this.queryUtility.fetch();
    }

    @action.bound
    updateFeaturedListStationStatus(stationId, isTurnedOn) {
        const item = find(this.homeFeaturedContentViewStore.items, (x) => x.stationId === stationId);
        if (item) {
            item.isTurnedOn = isTurnedOn;
        }
    }

    @action.bound
    updateFeaturedListPodcastStatus(podcastId, isTurnedOn) {
        const item = find(this.homeFeaturedContentViewStore.items, (x) => x.podcastId === podcastId);
        if (item) {
            item.isTurnedOn = isTurnedOn;
        }
    }

    onDestroy() {
        this.homeGenericSearchViewStore.destroy();
        this.homeFeaturedContentViewStore.destroy();
        this.homeManageSelectedItemsViewStore.destroy();
    }
}

export default HomeViewStore;
