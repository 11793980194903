import { MrbBaseRouteStore } from "mrb/core";
import { NakiNigunAnalyticsService } from "common/services";

class NakiNigunAlbumActivityRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = rootStore.createApplicationService(NakiNigunAnalyticsService);
    }

    async getNakiNigunAlbumActivity(nakiNigunAlbumId, filter, abortController) {
        const response = await this.service.getNakiNigunAlbumActivity(nakiNigunAlbumId, filter, abortController);
        return response.data;
    }

    async getNakiNigunAlbumSummary(nakiNigunAlbumId, filter, abortController) {
        const response = await this.service.getNakiNigunAlbumSummary(nakiNigunAlbumId, filter, abortController);
        return response.data;
    }
}

export default NakiNigunAlbumActivityRouteStore;
