import { BaseApiRouteService } from "mrb/core";

class NakiNigunAnalyticsApiRouteService extends BaseApiRouteService {
    constructor() {
        super("analytics/naki-nigun");
    }

    getNakiNigunListenersStats() {
        return super.get(`${this.base}/listeners`);
    }

    getTrendingAlbumsForProducer(filter) {
        return super.find(
            `${this.base}/producer/{?from,to,previousCycleFrom,previousCycleTo,timeZone,page,rpp,uniqueListeners}`,
            filter
        );
    }

    getTrendingAlbumsTotalStreamsForProducer(filter) {
        return super.find(`${this.base}/producer-total/{?from,to,timeZone,uniqueListeners}`, filter);
    }

    getTrendingNakiNigunAlbums(filter) {
        return super.find(
            `${this.base}/{?from,to,previousCycleFrom,previousCycleTo,timeZone,page,rpp,uniqueListeners}`,
            filter
        );
    }

    getNakiNigunAlbumActivity(id, options) {
        return super.get(`${this.base}/{id}{?from,to,previousCycleFrom,previousCycleTo,timeZone}`, id, options);
    }

    getNakiNigunAlbumSummary(id, options) {
        return super.get(
            `${this.base}/{id}/summary{?from,to,previousCycleFrom,previousCycleTo,embed,fields}`,
            id,
            options
        );
    }
}

export default NakiNigunAnalyticsApiRouteService;
